/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import { getDeepValue as n, setDeepValue as i } from "../../core/object.js";
import { union as t } from "../../core/SetUtils.js";
import { parseWhereClause as r } from "../../core/sql.js";
import { validateDomainValue as l, getDomainRange as o, DomainValidationError as s } from "./domainUtils.js";
import { loadArcade as a } from "../../support/arcadeOnDemand.js";
const u = /^([0-9_])/,
  f = /[^a-z0-9_\u0080-\uffff]+/gi;
function c(e) {
  if (null == e) return null;
  return e.trim().replaceAll(f, "_").replace(u, "F$1") || null;
}
const d = ["field", "field2", "field3", "normalizationField", "rotationInfo.field", "proportionalSymbolInfo.field", "proportionalSymbolInfo.normalizationField", "colorInfo.field", "colorInfo.normalizationField"],
  m = ["field", "normalizationField"];
function p(e, n) {
  if (null != e && null != n) for (const i of Array.isArray(e) ? e : [e]) if (y(d, i, n), "visualVariables" in i && i.visualVariables) for (const e of i.visualVariables) y(m, e, n);
}
function y(e, t, r) {
  if (e) for (const l of e) {
    const e = n(l, t),
      o = e && "function" != typeof e && r.get(e);
    o && i(l, o.name, t);
  }
}
function g(e, n) {
  if (null != e && n?.fields?.length) if ("startField" in e) {
    const i = n.get(e.startField),
      t = n.get(e.endField);
    e.startField = i?.name ?? null, e.endField = t?.name ?? null;
  } else {
    const i = n.get(e.startTimeField),
      t = n.get(e.endTimeField);
    e.startTimeField = i?.name ?? null, e.endTimeField = t?.name ?? null;
  }
}
const F = new Set();
function I(e, n) {
  return e && n ? (F.clear(), b(F, e, n), Array.from(F).sort()) : [];
}
function b(e, n, i) {
  if (i) if (n?.fields?.length) {
    if (i.includes("*")) for (const {
      name: t
    } of n.fields) e.add(t);else for (const t of i) w(e, n, t);
  } else {
    if (i.includes("*")) return e.clear(), void e.add("*");
    for (const n of i) null != n && e.add(n);
  }
}
function w(e, n, i) {
  if ("string" == typeof i) if (n) {
    const t = n.get(i);
    t && e.add(t.name);
  } else e.add(i);
}
function x(e, n) {
  return null == n || null == e ? [] : n.includes("*") ? (e.fields ?? []).map(e => e.name) : n;
}
function T(e, n, i = 1) {
  if (!n || !e) return [];
  if (n.includes("*")) return ["*"];
  const t = I(e, n);
  return t.length / e.fields.length >= i ? ["*"] : t;
}
async function h(e, n, i) {
  if (!i) return;
  const {
      arcadeUtils: t
    } = await a(),
    r = t.extractFieldNames(i, n?.fields?.map(e => e.name));
  for (const l of r) w(e, n, l);
}
async function E(n, i, t) {
  if (t && "1=1" !== t) {
    const l = await r(t, i);
    if (!l.isStandardized) throw new e("fieldUtils:collectFilterFields", "Where clause is not standardized", {
      where: t
    });
    b(n, i, l.fieldNames);
  }
}
function _({
  displayField: e,
  fields: n
}) {
  return e || (n?.length ? S(n, "name-or-title") || S(n, "unique-identifier") || S(n, "type-or-category") || A(n) : null);
}
function A(e) {
  for (const n of e) {
    if (!n?.name) continue;
    const e = n.name.toLowerCase();
    if (e.includes("name") || e.includes("title")) return n.name;
  }
  return null;
}
function S(e, n) {
  for (const i of e) if (i?.valueType && i.valueType === n) return i.name;
  return null;
}
async function N(e) {
  if (!e) return [];
  const n = new Set();
  return await v(n, e), Array.from(n).sort();
}
async function v(e, n) {
  if (!n) return;
  const i = n.elevationInfo?.featureExpressionInfo;
  return i ? i.collectRequiredFields(e, n.fieldsIndex) : void 0;
}
function $(e, n, i) {
  i.onStatisticExpression ? h(e, n, i.onStatisticExpression.expression) : e.add(i.onStatisticField);
}
async function L(e, n, i) {
  if (!n || !i || !("fields" in i)) return;
  const t = [],
    r = i.popupTemplate;
  t.push(D(e, n, r)), i.fields && t.push(...i.fields.map(async i => $(e, n.fieldsIndex, i))), await Promise.all(t);
}
async function D(e, n, i) {
  const t = [];
  i?.expressionInfos && t.push(...i.expressionInfos.map(i => h(e, n.fieldsIndex, i.expression)));
  const r = i?.content;
  if (Array.isArray(r)) for (const l of r) "expression" === l.type && l.expressionInfo && t.push(h(e, n.fieldsIndex, l.expressionInfo.expression));
  await Promise.all(t);
}
async function M(e, n, i) {
  n && (n.timeInfo && i?.timeExtent && b(e, n.fieldsIndex, [n.timeInfo.startField, n.timeInfo.endField]), n.floorInfo && b(e, n.fieldsIndex, [n.floorInfo.floorField]), null != i?.where && (await E(e, n.fieldsIndex, i.where)));
}
async function O(e, n, i) {
  n && i && (await Promise.all(i.map(i => V(e, n, i))));
}
async function V(e, n, i) {
  n && i && (i.valueExpression ? await h(e, n.fieldsIndex, i.valueExpression) : i.field && w(e, n.fieldsIndex, i.field));
}
async function U(e) {
  if (!e) return [];
  const n = "timeInfo" in e && e.timeInfo;
  return n ? I(e.fieldsIndex, [e.trackIdField, n.startField, n.endField]) : [];
}
function j(e) {
  return e ? I(e.fieldsIndex, z(e)) : [];
}
function R(e) {
  if (!e) return [];
  const n = e.geometryFieldsInfo;
  return n ? I(e.fieldsIndex, [n.shapeAreaField, n.shapeLengthField]) : [];
}
const C = new Set(["oid", "global-id", "guid"]),
  G = new Set(["oid", "global-id"]),
  k = [/^fnode_$/i, /^tnode_$/i, /^lpoly_$/i, /^rpoly_$/i, /^poly_$/i, /^shape$/i, /^shape_$/i, /^subclass$/i, /^subclass_$/i, /^rings_ok$/i, /^rings_nok$/i, /perimeter/i, /objectid/i, /_i$/i];
function X(e) {
  const n = new Set();
  P(e).forEach(e => n.add(e)), R(e).forEach(e => n.add(e.toLowerCase()));
  const i = e && "infoFor3D" in e ? e.infoFor3D : void 0;
  return i && (Object.values(i.assetMapFieldRoles).forEach(e => n.add(e.toLowerCase())), Object.values(i.transformFieldRoles).forEach(e => n.add(e.toLowerCase()))), Array.from(n);
}
function z(e) {
  if (!e) return [];
  const n = "editFieldsInfo" in e && e.editFieldsInfo;
  if (!n) return [];
  const {
    creationDateField: i,
    creatorField: t,
    editDateField: r,
    editorField: l
  } = n;
  return [i, t, r, l].filter(Boolean);
}
function P(e) {
  return z(e).map(e => e.toLowerCase());
}
function q(e, n) {
  return e.editable && !C.has(e.type) && !P(n).includes(e.name?.toLowerCase() ?? "");
}
function B(e, n) {
  const i = e.name?.toLowerCase() ?? "";
  return !(null != n?.objectIdField && i === n.objectIdField.toLowerCase() || null != n?.globalIdField && i === n.globalIdField.toLowerCase() || X(n).includes(i) || G.has(e.type) || k.some(e => e.test(i)));
}
async function Y(e) {
  if (!e) return [];
  const n = new Set();
  return await J(n, e), Array.from(n).sort();
}
async function J(e, n) {
  const {
    labelingInfo: i,
    fieldsIndex: t
  } = n;
  i?.length && (await Promise.all(i.map(n => W(e, t, n))));
}
async function W(e, n, i) {
  if (!i) return;
  const t = i.getLabelExpression(),
    r = i.where;
  if ("arcade" === t.type) await h(e, n, t.expression);else {
    const i = t.expression.match(/{[^}]*}/g);
    i && i.forEach(i => {
      w(e, n, i.slice(1, -1));
    });
  }
  await E(e, n, r);
}
function H(e) {
  const n = e.defaultValue;
  return void 0 !== n && re(e, n) ? n : e.nullable ? null : void 0;
}
function K(e) {
  const n = "string" == typeof e ? {
    type: e
  } : e;
  return ye(n) ? 255 : "esriFieldTypeDate" === n.type || "date" === n.type ? 8 : void 0;
}
function Q(e) {
  return "number" == typeof e && !isNaN(e) && isFinite(e);
}
function Z(e) {
  return null === e || Q(e);
}
function ee(e) {
  return null === e || Number.isInteger(e);
}
function ne(e) {
  return null != e && "string" == typeof e;
}
function ie(e) {
  return null === e || ne(e);
}
function te() {
  return !0;
}
function re(e, n) {
  let i;
  switch (e.type) {
    case "date":
    case "integer":
    case "long":
    case "small-integer":
    case "big-integer":
    case "esriFieldTypeDate":
    case "esriFieldTypeInteger":
    case "esriFieldTypeLong":
    case "esriFieldTypeSmallInteger":
    case "esriFieldTypeBigInteger":
      i = e.nullable ? ee : Number.isInteger;
      break;
    case "double":
    case "single":
    case "esriFieldTypeSingle":
    case "esriFieldTypeDouble":
      i = e.nullable ? Z : Q;
      break;
    case "string":
    case "esriFieldTypeString":
      i = e.nullable ? ie : ne;
      break;
    default:
      i = te;
  }
  return 1 === arguments.length ? i : i(n);
}
const le = ["integer", "small-integer", "big-integer"],
  oe = ["single", "double"],
  se = [...le, ...oe],
  ae = ["esriFieldTypeInteger", "esriFieldTypeSmallInteger", "esriFieldTypeBigInteger"],
  ue = ["esriFieldTypeSingle", "esriFieldTypeDouble"],
  fe = new Set([...le, ...ae]),
  ce = new Set([...oe, ...ue]),
  de = t(fe, ce);
function me(e) {
  return null != e && fe.has(e.type);
}
function pe(e) {
  return null != e && de.has(e.type);
}
function ye(e) {
  return null != e && ("string" === e.type || "esriFieldTypeString" === e.type);
}
function ge(e) {
  return null != e && ("date" === e.type || "esriFieldTypeDate" === e.type);
}
function Fe(e) {
  return null != e && ("date-only" === e.type || "esriFieldTypeDateOnly" === e.type);
}
function Ie(e) {
  return null != e && ("timestamp-offset" === e.type || "esriFieldTypeTimestampOffset" === e.type);
}
function be(e) {
  return null != e && ("time-only" === e.type || "esriFieldTypeTimeOnly" === e.type);
}
function we(e) {
  return null != e && ("oid" === e.type || "esriFieldTypeOID" === e.type);
}
function xe(e) {
  return null != e && ("global-id" === e.type || "esriFieldTypeGlobalID" === e.type);
}
function Te(e, n) {
  return null === Ae(e, n);
}
var he, Ee;
function _e(e) {
  return null == e || "number" == typeof e && isNaN(e) ? null : e;
}
function Ae(e, n) {
  return null == e || e.nullable && null === n ? null : pe(e) && !Se(e.type, Number(n)) ? he.OUT_OF_RANGE : re(e, n) ? e.domain ? l(e, n) : null : Ee.INVALID_TYPE;
}
function Se(e, n) {
  const i = "string" == typeof e ? ve(e) : e;
  if (!i) return !1;
  const t = i.min,
    r = i.max;
  return i.isInteger ? Number.isInteger(n) && n >= t && n <= r : n >= t && n <= r;
}
function Ne(e, n) {
  const i = o(e, n);
  return i || (pe(e) ? ve(e.type) : void 0);
}
function ve(e) {
  switch (e) {
    case "esriFieldTypeSmallInteger":
    case "small-integer":
      return Le;
    case "esriFieldTypeInteger":
    case "integer":
      return De;
    case "esriFieldTypeBigInteger":
    case "big-integer":
      return Me;
    case "esriFieldTypeSingle":
    case "single":
      return Oe;
    case "esriFieldTypeDouble":
    case "double":
      return Ve;
  }
}
function $e(e) {
  if (!Q(e)) return null;
  if (Number.isInteger(e)) {
    if (e >= Le.min && e <= Le.max) return "esriFieldTypeSmallInteger";
    if (e >= De.min && e <= De.max) return "esriFieldTypeInteger";
    if (e >= Me.min && e <= Me.max) return "esriFieldTypeBigInteger";
  }
  return e >= Oe.min && e <= Oe.max ? "esriFieldTypeSingle" : "esriFieldTypeDouble";
}
!function (e) {
  e.OUT_OF_RANGE = "numeric-range-validation-error::out-of-range";
}(he || (he = {})), function (e) {
  e.INVALID_TYPE = "type-validation-error::invalid-type";
}(Ee || (Ee = {}));
const Le = {
    min: -32768,
    max: 32767,
    isInteger: !0,
    rawMin: -32768,
    rawMax: 32767
  },
  De = {
    min: -2147483648,
    max: 2147483647,
    isInteger: !0,
    rawMin: -2147483648,
    rawMax: 2147483647
  },
  Me = {
    min: -Number.MAX_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    isInteger: !0,
    rawMin: -Number.MAX_SAFE_INTEGER,
    rawMax: Number.MAX_SAFE_INTEGER
  },
  Oe = {
    min: -34e37,
    max: 12e37,
    isInteger: !1,
    rawMin: -34e37,
    rawMax: 12e37
  },
  Ve = {
    min: -Number.MAX_VALUE,
    max: Number.MAX_VALUE,
    isInteger: !1,
    rawMin: -Number.MAX_VALUE,
    rawMax: Number.MAX_VALUE
  };
function Ue(e, n, i) {
  switch (e) {
    case s.INVALID_CODED_VALUE:
      return `Value ${i} is not in the coded domain - field: ${n.name}, domain: ${JSON.stringify(n.domain)}`;
    case s.VALUE_OUT_OF_RANGE:
      return `Value ${i} is out of the range of valid values - field: ${n.name}, domain: ${JSON.stringify(n.domain)}`;
    case Ee.INVALID_TYPE:
      return `Value ${i} is not a valid value for the field type - field: ${n.name}, type: ${n.type}, nullable: ${n.nullable}`;
    case he.OUT_OF_RANGE:
      {
        const {
          min: e,
          max: t
        } = ve(n.type);
        return `Value ${i} is out of range for the number type - field: ${n.name}, type: ${n.type}, value range is ${e} to ${t}`;
      }
  }
}
function je(e, n) {
  return !Re(e, n, null);
}
function Re(e, n, i) {
  if (!n?.attributes || !e) {
    if (null != i) for (const n of e ?? []) i.add(n);
    return !0;
  }
  const t = new Set(Object.keys(n.attributes));
  let r = !1;
  for (const l of e) if (!t.has(l)) {
    if (r = !0, null == i) break;
    i.add(l);
  }
  return r;
}
async function Ce(e, n) {
  const i = new Set();
  for (const t of n) await h(i, e.fieldsIndex, t);
  return Array.from(i).sort();
}
function Ge(e) {
  return !!e && ["raster.itempixelvalue", "raster.servicepixelvalue"].some(n => e.toLowerCase().startsWith(n));
}
async function ke(e, n) {
  const i = new Set();
  return e?.collectRequiredFields && (await e.collectRequiredFields(i, n)), Array.from(i).sort();
}
function Xe(e) {
  const n = e?.match(/{[^}]+}/g);
  return n ? n.map(e => e.slice(1, -1).split(":")[0].trim()) : [];
}
export { he as NumericRangeValidationError, Ee as TypeValidationError, Me as bigIntegerRange, h as collectArcadeFieldNames, v as collectElevationFields, L as collectFeatureReductionFields, w as collectField, b as collectFields, M as collectFilterFields, J as collectLabelingFields, O as collectOrderByInfos, D as collectPopupTemplateFields, Ve as doubleRange, Xe as extractSubstitutionTemplatesFromString, je as featureHasFields, I as fixFields, p as fixRendererFields, g as fixTimeInfoFields, ue as floatJSONTypes, oe as floatTypes, _ as getDisplayFieldName, z as getEditTrackingFields, N as getElevationFields, Ce as getExpressionFields, j as getFeatureEditFields, R as getFeatureGeometryFields, K as getFieldDefaultLength, H as getFieldDefaultValue, Ne as getFieldRange, Y as getLabelingFields, X as getLowerCaseDefaultHiddenFields, P as getLowerCaseEditTrackingFields, $e as getNumericTypeForValue, ke as getRendererFields, U as getTimeFields, ae as integerJSONTypes, De as integerRange, le as integerTypes, ge as isDateField, Fe as isDateOnlyField, q as isFieldEditable, B as isFieldVisibleByDefault, xe as isGlobalIDField, me as isIntegerField, Se as isNumberInRange, pe as isNumericField, we as isObjectIDField, Ge as isRasterPixelValueField, ye as isStringField, be as isTimeOnlyField, Ie as isTimestampOffsetField, Te as isValidFieldValue, re as isValueMatchingFieldType, c as normalizeFieldName, se as numericTypes, T as packFields, Re as populateMissingFields, d as rendererFields, _e as sanitizeNullFieldValue, Oe as singleRange, Le as smallIntegerRange, x as unpackFieldNames, Ae as validateFieldValue, Ue as validationErrorToString, m as visualVariableFields };