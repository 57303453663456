/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n) {
  return "number" == typeof n;
}
function t(n) {
  return "string" == typeof n || n instanceof String;
}
export { n as isNumber, t as isString };