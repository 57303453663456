/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../geometry.js";
import e from "../core/Error.js";
import r from "../core/Logger.js";
import t from "../geometry/SpatialReference.js";
const a = () => r.getLogger("esri.support.arcadeOnDemand");
let s;
function i() {
  return s || (s = (async () => {
    const e = await import("./arcadeUtils.js");
    return {
      arcade: e.arcade,
      arcadeUtils: e,
      Dictionary: e.Dictionary,
      Feature: e.arcadeFeature
    };
  })()), s;
}
const c = (e, r, t) => p.create(e, r, t, null, ["$feature", "$view"], []),
  n = (e, r) => p.create(e, r.spatialReference, r.fields, null, ["$feature", "$view"], []),
  o = (e, r, t) => p.create(e, r, t, null, ["$feature", "$view"], []),
  l = o,
  u = (e, r, t, a) => p.create(e, r, t, a, ["$feature", "$view"], []);
class p {
  constructor(e, r, t, a, s, i, c) {
    this.services = null, this.script = e, this.evaluate = a;
    const n = Array.isArray(i) ? i : i?.fields;
    this.fields = n ?? [], this._syntaxTree = t, this._arcade = r, this._arcadeFeature = s, this._spatialReference = c, this._referencesGeometry = r.scriptTouchesGeometry(this._syntaxTree), this._referencesScale = this._arcade.referencesMember(this._syntaxTree, "scale");
  }
  static async create(r, s, c, n, o, l) {
    const {
        arcade: u,
        Feature: f,
        Dictionary: m
      } = await i(),
      d = s instanceof t ? s : t.fromJSON(s);
    let y;
    try {
      y = u.parseScript(r, l);
    } catch (x) {
      return a().error(new e("arcade-bad-expression", "Failed to parse arcade script", {
        script: r,
        error: x
      })), null;
    }
    const h = o.reduce((e, r) => ({
      ...e,
      [r]: null
    }), {});
    let w = null;
    null != n && (w = new m(n), w.immutable = !0, h.$config = null);
    const v = u.scriptUsesGeometryEngine(y),
      $ = v && u.enableGeometrySupport(),
      g = u.scriptUsesFeatureSet(y) && u.enableFeatureSetSupport(),
      _ = u.scriptIsAsync(y),
      F = _ && u.enableAsyncSupport(),
      S = {
        vars: h,
        spatialReference: d,
        useAsync: !!F
      };
    await Promise.all([$, g, F]);
    const b = new Set();
    await u.loadDependentModules(b, y, null, _, v);
    const R = new m();
    R.immutable = !1, R.setField("scale", 0);
    const j = u.compileScript(y, S),
      G = (e, r) => {
        const t = e.$view?.timeZone;
        return "$view" in e && e.$view && (R.setField("scale", "object" == typeof e.$view && "scale" in e.$view ? e.$view.scale : void 0), e.$view = R), w && (e.$config = w), j({
          vars: e,
          spatialReference: d,
          services: r,
          timeZone: t
        });
      };
    return new p(r, u, y, G, new f(), c, d);
  }
  repurposeFeature(e) {
    return e.geometry && !e.geometry.spatialReference && (e.geometry.spatialReference = this._spatialReference), this._arcadeFeature.repurposeFromGraphicLikeObject(e.geometry, e.attributes, {
      fields: this.fields
    }), this._arcadeFeature;
  }
  referencesGeometry() {
    return this._referencesGeometry;
  }
  referencesScale() {
    return this._referencesScale;
  }
}
export { p as ArcadeExpression, u as createDictionaryExpression, c as createLabelExpression, o as createRendererExpression, n as createRendererExpressionWithOptions, l as createVVExpression, i as loadArcade };