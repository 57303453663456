/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../intl.js";
import "../../core/has.js";
import t from "../../core/Error.js";
import { clamp as e } from "../../core/mathUtils.js";
import { mappedFind as n } from "../../core/maybe.js";
import { convertDateFormatToIntlOptions as o, formatTimestamp as l, formatTimeOnly as i, formatDateOnly as r, formatDate as u } from "../../intl/date.js";
import { utc as a } from "../../time/timeZoneUtils.js";
import { isString as s, isNumber as m } from "./dataUtils.js";
import { DateTime as c, FixedOffsetZone as f } from "luxon";
import { getLocale as d } from "../../intl/locale.js";
const y = a,
  p = {
    date: null,
    time: null,
    timeZoneOffset: null
  },
  h = new Map([["hours", 3600], ["minutes", 60], ["seconds", 1], ["deciseconds", .1], ["centiseconds", .01], ["milliseconds", .001]]);
var S;
!function (t) {
  t.HM = "HH:mm", t.HMS = "HH:mm:ss", t.HMS_MS = "HH:mm:ss.SSS";
}(S || (S = {}));
const v = [S.HMS_MS, S.HMS, S.HM, "TT"],
  M = "yyyy-MM-dd",
  O = S.HMS,
  w = "latn",
  g = new Set(["date-only", "time-only", "timestamp-offset"]),
  H = t => "valueAsDate" in t;
function Z(t) {
  return {
    locale: d(),
    numberingSystem: w,
    zone: t ?? y
  };
}
function j(t) {
  return t?.isValid ? t.toMillis() : null;
}
function x(t, e) {
  return null === t ? null : c.fromMillis(t, Z(e));
}
function I(t, e) {
  if (null == t || !s(t) || "" === t) {
    if (e) {
      const t = c.local({
        zone: e
      });
      return {
        date: null,
        time: null,
        timeZoneOffset: t.isValid ? t.offset.toString() : null
      };
    }
    return p;
  }
  const n = c.fromISO(t, {
    setZone: !0
  });
  return {
    date: n.toFormat(M, Z()),
    time: n.toFormat(S.HMS_MS, Z()),
    timeZoneOffset: n.offset.toString()
  };
}
function V(t, e) {
  if (null == t || !m(t) || isNaN(t)) return p;
  const n = x(t, e);
  return n ? {
    date: n.toFormat(M, Z(e)),
    time: n.toFormat(O, Z(e))
  } : p;
}
function b(t) {
  switch (t.type) {
    case "date":
    default:
      return o("short-date-short-time");
    case "date-only":
      return o("short-date");
    case "time-only":
      return o("short-time");
    case "timestamp-offset":
      return {
        ...o("short-date-short-time"),
        timeZone: void 0,
        timeZoneName: "short"
      };
  }
}
function F(t, e, n) {
  if (!t || null == e) return null;
  switch (t.type) {
    case "date":
      return u(e, n);
    case "date-only":
      return r(e, n);
    case "time-only":
      return i(e, n);
    case "timestamp-offset":
      return l(e, n);
    default:
      return null;
  }
}
function T(t) {
  const {
    dateComponent: e,
    defaultTimeZone: n,
    timeComponent: o,
    timeZoneComponent: l,
    oldValue: i
  } = t;
  if (!e?.value) return null;
  const {
      year: r,
      month: u,
      day: s
    } = c.fromFormat(e.value, M),
    {
      hour: m,
      minute: d,
      second: y,
      millisecond: p
    } = C(o, a) ?? c.now(),
    h = I(i, n),
    S = l?.value ? parseInt(l.value, 10) : null != h.timeZoneOffset ? parseInt(h.timeZoneOffset, 10) : new Date().getTimezoneOffset(),
    v = f.instance(S),
    O = c.fromObject({
      year: r,
      month: u,
      day: s,
      hour: m,
      minute: d,
      second: y,
      millisecond: p
    }, {
      zone: v
    });
  return O.isValid ? O.toISO({
    includeOffset: !0
  }) : null;
}
function D(t, e = "system") {
  return c.fromJSDate(t, {
    zone: e
  }).isInDST;
}
function k(t) {
  const {
      dateComponent: e,
      timeComponent: n,
      timeZone: o,
      max: l,
      min: i,
      oldValue: r
    } = t,
    u = C(e, o);
  let a = !!t.applyRange;
  if (!u) return null;
  let s = null;
  if (null != n?.value) {
    const t = C(n, o),
      e = Date.now(),
      i = null != l && l < e ? l : e,
      r = c.fromMillis(i, Z(o)),
      a = u || r,
      {
        year: m,
        month: f,
        day: d
      } = a,
      {
        hour: y,
        minute: p,
        second: h,
        millisecond: S
      } = t || r;
    s = a.set({
      year: m,
      month: f,
      day: d,
      hour: y,
      minute: p,
      second: h,
      millisecond: S
    });
  } else {
    const t = x(N({
        value: r ?? Date.now(),
        max: l,
        min: i
      }), o),
      {
        year: e,
        month: n,
        day: m
      } = u,
      {
        hour: c,
        minute: f,
        second: d,
        millisecond: y
      } = t;
    a = !0, s = u.set({
      year: e,
      month: n,
      day: m,
      hour: c,
      minute: f,
      second: d,
      millisecond: y
    });
  }
  if (!s.isValid) return null;
  const m = s.toMillis();
  return a ? N({
    value: m,
    max: l,
    min: i
  }) : m;
}
function C(t, e) {
  if (null == t?.value || Array.isArray(t.value)) return null;
  let n = null;
  return n = H(t) ? c.fromFormat(t.value, M, Z(e)) : J(t.value), n?.isValid ? n : null;
}
function z(t) {
  const {
    max: e,
    min: n,
    value: o
  } = t;
  return !isNaN(o) && !(null != e && o > e) && !(null != n && o < n);
}
function N(t) {
  const {
    max: n,
    min: o,
    value: l
  } = t;
  return null != n && null != o ? e(l, o, n) : null != n && l > n ? n : null != o && l < o ? o : l;
}
function A(e) {
  if (!e) return "";
  const n = e.split(".").at(0);
  if (!n || n.length < 1) throw new t("invalid time-only field", `Cannot parse valid time-only field value from ${e}`);
  const o = n.split(":"),
    l = new Array(3);
  for (let t = 0; t < 3; t++) {
    const e = o.at(t) ?? "";
    l[t] = e.padStart(2, "0");
  }
  return l.join(":");
}
function U(t, e) {
  switch (e) {
    case "date":
      return t.getTime();
    case "date-only":
      return t.toISODate();
    case "time-only":
      return A(t.toISOTime(!0, !1));
    case "timestamp-offset":
      return t.toISOString(!1);
    default:
      return null;
  }
}
function _(t) {
  return !!t && "object" == typeof t && "getTime" in t && "toISOString" in t && "timeZone" in t;
}
function E(t) {
  if (!t || !s(t)) return null;
  const e = c.fromFormat(t, M);
  return e.isValid ? e : null;
}
function J(t) {
  return t && s(t) ? n(v, e => {
    const n = c.fromFormat(t, e);
    return n.isValid ? n : null;
  }) ?? null : null;
}
function R(t) {
  if (!t || !s(t)) return null;
  const e = c.fromISO(t);
  return e.isValid ? e : null;
}
function $(t) {
  const {
    type: e,
    range: n,
    value: o
  } = t;
  if (null == o || !n || o === n.min || o === n.max) return !0;
  if ("date" === e) return z({
    value: o,
    min: n.min,
    max: n.max
  });
  const {
    rawMax: l,
    rawMin: i
  } = n;
  let r = null,
    u = null,
    a = null;
  switch (e) {
    case "date-only":
      r = E(l), u = E(i), a = E(o);
      break;
    case "time-only":
      r = J(l), u = J(i), a = J(o);
      break;
    case "timestamp-offset":
      r = R(l), u = R(i), a = R(o);
  }
  return !a || z({
    value: a.valueOf(),
    min: u?.valueOf(),
    max: r?.valueOf()
  });
}
const q = new Set(["small-integer", "big-integer", "integer", "single", "double"]);
function B(t) {
  return q.has(t);
}
const G = new Set(["date-picker", "time-picker", "datetimeoffset-picker", "datetime-picker"]);
function K(t) {
  return G.has(t);
}
export { S as TimeStampOffsetPattern, U as arcadeDateToFieldValue, N as clampValueToRange, x as createDateTimeFromFieldValue, g as dateFieldsWithStringFieldValue, E as dateOnlyToDateTime, $ as dateTimeIsInRange, j as dateTimeToFieldValue, M as defaultDatePattern, O as defaultTimePattern, Z as getDefaultLocaleOptions, T as getISOFieldValueFromDateComponents, b as getIntlOptionsForField, F as getLabelForDateFieldValue, C as getLocalDateTimeFromComponent, k as getUnixFieldValueFromDateComponents, _ as isArcadeDate, H as isCalciteDateComponent, K as isDateType, D as isInDST, B as isNumberType, A as normalizeTimeOnlyString, w as numberingSystem, I as prepareISOFieldValueForDateComponents, V as prepareUnixFieldValueForDateComponents, v as supportedTimeFormats, J as timeOnlyToDateTime, h as timeResolutionToStepMap, R as timestampOffsetToDateTime, z as valueIsInRange };