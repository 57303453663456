/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { dateOnlyToDateTime as e, timeOnlyToDateTime as n, timestampOffsetToDateTime as i } from "../../widgets/support/dateUtils.js";
var l;
!function (e) {
  e.VALUE_OUT_OF_RANGE = "domain-validation-error::value-out-of-range", e.INVALID_CODED_VALUE = "domain-validation-error::invalid-coded-value";
}(l || (l = {}));
const t = new Set(["integer", "small-integer", "big-integer", "esriFieldTypeInteger", "esriFieldTypeSmallInteger", "esriFieldTypeBigInteger"]);
function r(e) {
  return null != e && t.has(e.type);
}
function a(e) {
  return null != e && ("date-only" === e.type || "esriFieldTypeDateOnly" === e.type);
}
function u(e) {
  return null != e && ("timestamp-offset" === e.type || "esriFieldTypeTimestampOffset" === e.type);
}
function o(e) {
  return null != e && ("time-only" === e.type || "esriFieldTypeTimeOnly" === e.type);
}
function s(e, n, i) {
  const t = i ?? e?.domain;
  if (!t) return null;
  switch (t.type) {
    case "range":
      {
        const {
          min: t,
          max: r
        } = m(e, i);
        if (null != t && +n < t || null != r && +n > r) return l.VALUE_OUT_OF_RANGE;
        break;
      }
    case "coded-value":
    case "codedValue":
      if (null == t.codedValues || t.codedValues.every(e => null == e || e.code !== n)) return l.INVALID_CODED_VALUE;
  }
  return null;
}
function m(e, n) {
  const i = n ?? e?.domain;
  if (!i || "range" !== i.type) return;
  const l = "range" in i ? i.range[0] : i.minValue,
    t = "range" in i ? i.range[1] : i.maxValue,
    s = r(e);
  return a(e) || o(e) || u(e) ? {
    ...d(e, t, l),
    isInteger: s
  } : {
    min: null != l && "number" == typeof l ? l : null,
    max: null != t && "number" == typeof t ? t : null,
    rawMin: l,
    rawMax: t,
    isInteger: s
  };
}
function d(l, t, r) {
  return a(l) ? {
    min: e(r)?.toMillis(),
    max: e(t)?.toMillis(),
    rawMin: r,
    rawMax: t
  } : o(l) ? {
    min: n(r)?.toMillis(),
    max: n(t)?.toMillis(),
    rawMin: r,
    rawMax: t
  } : u(l) ? {
    min: i(r)?.toMillis(),
    max: i(t)?.toMillis(),
    rawMin: r,
    rawMax: t
  } : {
    max: null,
    min: null
  };
}
export { l as DomainValidationError, d as dateTimeFieldValuesToNumericRange, m as getDomainRange, s as validateDomainValue };